import React, {useCallback} from 'react';
import * as Yup from 'yup';
import cpf from 'cpf';
import Modal from 'react-bootstrap/Modal';
import {
    useForm,
    FormProvider,
    UseFormGetValues,
    UseFormSetValue
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {validate} from 'cnpj';
import Button from '../../../../components/Button';
import {MDFeProps} from '../../../../interfaces';
import InputLabelCNPJnoSearch from '../../../../components/InputLabelCNPJnoSearch';
import InputLabelCPF from '../../../../components/InputLabelCPF';
import InputLabelOnlyNumbers from '../../../../components/InputLabelOnlyNumbers';
import InputLabelCurrency from '../../../../components/InputLabelCurrency';

interface Props {
    hide: () => void;
    getTableValues: UseFormGetValues<MDFeProps>;
    setTableValue: UseFormSetValue<MDFeProps>;
}

interface ValePedagio {
    cnpj_company?: string;
    cnpj_company_payer?: string;
    number?: string;
    cpf_payer?: string;
    value: number;
}

const validations = Yup.object({
    cnpj_company: Yup.string()
        .required('Empresa fornecedora do vale pedário.')
        .test('cnpj', 'CNPJ inválido.', (value) => {
            if (value !== undefined) {
                return validate(value);
            }
            return true;
        }),
    cnpj_company_payer: Yup.string().test('cnpj', 'CNPJ inválido.', (value) => {
        if (value !== '' && value !== undefined) {
            return validate(value);
        }
        return true;
    }),
    cpf_payer: Yup.string().test('cpf', 'CPF inválido.', (value) => {
        if (value !== '' && value !== undefined) {
            return cpf.isValid(value);
        }
        return true;
    }),
    number: Yup.string().required('Compra Obrigatório.'),
    value: Yup.number().required('Valor Obrigatório.')
});

const ValePedagioModal: React.FC<Props> = ({
    hide,
    getTableValues,
    setTableValue
}) => {
    const methods = useForm<ValePedagio>({
        resolver: yupResolver(validations),
        defaultValues: {
            cnpj_company: '',
            cnpj_company_payer: '',
            number: '',
            cpf_payer: '',
            value: 0
        }
    });

    const {handleSubmit} = methods;

    const onSubmit = useCallback((dataSubmit: ValePedagio) => {
        setTableValue('vale_pedagio', [
            ...getTableValues().vale_pedagio,
            dataSubmit
        ]);
        hide();
        console.log(dataSubmit);
    }, []);

    return (
        <>
            <Modal show dialogAs="div" centered>
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                        className="responsive-modal"
                    >
                        <Modal.Dialog scrollable size="lg" centered>
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    Cadastrar informação de vale pedágio
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="card-body p-0">
                                    <div className="row">
                                        <InputLabelCNPJnoSearch
                                            label="Cnpj Fornecedor"
                                            name="cnpj_company"
                                            size_label="2"
                                            size_input="4"
                                        />
                                    </div>

                                    <div className="row">
                                        <InputLabelCNPJnoSearch
                                            label="Cnpj Pagador"
                                            name="cnpj_company_payer"
                                            size_label="2"
                                            size_input="4"
                                        />
                                    </div>

                                    <div className="row">
                                        <InputLabelOnlyNumbers
                                            label="Comprovante Compra"
                                            name="number"
                                            size_label="2"
                                            size_input="6"
                                        />
                                    </div>

                                    <div className="row">
                                        <InputLabelCPF
                                            label="Cpf Pagador"
                                            name="cpf_payer"
                                            size_label="2"
                                            size_input="4"
                                        />
                                    </div>

                                    <div className="row">
                                        <InputLabelCurrency
                                            label="Valor"
                                            name="value"
                                            size_label="2"
                                            size_input="4"
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                <div className="float-left">
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Retornar"
                                            onClick={() => hide()}
                                        />
                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                        />
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal.Dialog>
                    </form>
                </FormProvider>
            </Modal>
        </>
    );
};

export default ValePedagioModal;
