/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import Modal from 'react-bootstrap/esm/Modal';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import Button from '../../../components/Button';
import api from '../../../services/api';
import VehicleModal from '../../Vehicle/Modal';
import PeopleModal from '../../People/ModalForm';
import {useAuth} from '../../../hooks/Auth';
import Table from '../../../components/Table';
import {
    MDFeProps,
    PeopleProps,
    VehicleProps,
    EnvironmentProps,
    InsuranceProps
} from '../../../interfaces';

import validations from './validation';
import {peopleDefaultValues, ufs, vehicleDefaultValues} from '../../../data';
import InputLabelOnlyNumbers from '../../../components/InputLabelOnlyNumbers';
import InputLabel from '../../../components/InputLabel';
import SelectInputLabel from '../../../components/SelectInputLabel';
import AsyncSelectLabelCities from '../../../components/AsyncSelectLabelCities';
import InputLabelCEPnoSearch from '../../../components/InputLabelCEPnoSearch';
import InputLabelCurrency from '../../../components/InputLabelCurrency';
import InputLabelMeasurement from '../../../components/InputLabelMeasurement';
import InputLabelTextArea from '../../../components/InputLabelTextArea';
import FullPageLoader from '../../../components/FullPageLoader';
import EditIncludeButtons from '../../../components/EditIncludeButtons';
import helperTooltips from '../../../data/help';
import getChanges from '../../../utils/getChanges';
import searchCEP_MDFe from '../../../services/searchCEP_MDFe';
import CteNfeModal from './Modals/cteNfe';
import InsuranceModal from './Modals/insurance';
import ContractorModal from './Modals/contractor';
import CiotModal from './Modals/ciot';
import ValePedagioModal from './Modals/valePedagio';
import getDfeKeySerie from '../../../utils/getDfeKeySerie';
import getDfeKeyNumber from '../../../utils/getDfeKeyNumber';

interface Props {
    hide: () => void;
    refresh: () => void;
    data: MDFeProps;
}

const ModalMDF: React.FC<Props> = ({hide, refresh, data}) => {
    const {cities, peopleRefresh, setPeopleRefresh, enviromentText} = useAuth();
    const [environments, setEnvironments] = useState<EnvironmentProps[]>([]);
    const [rntrcIsRequired, setRntrcIsRequired] = useState(false);

    const [driver, setDriver] = useState<PeopleProps[]>([]);
    const [driverModalVisible, setDriverModalVisible] = useState(false);
    const [driverModalData, setDriverModalData] = useState<PeopleProps>({
        ...peopleDefaultValues,
        driver: true,
        type: 0
    });

    const [vehicles, setVehicles] = useState<VehicleProps[]>([]);
    const [trailers, setTrailers] = useState<VehicleProps[]>([]);
    const [vehicleModalVisible, setVehicleModalVisible] = useState(false);
    const [vehicleRefresh, setVehicleRefresh] = useState<number | null>(null);
    const [vehicleModalData, setVehicleModalData] = useState<VehicleProps>(
        vehicleDefaultValues
    );

    const [insurances, setInsurances] = useState<InsuranceProps[]>([]);
    const [insuranceRefresh, setInsuranceRefresh] = useState<number | null>(
        null
    );
    const [insuranceModalVisible, setInsuranceModalVisible] = useState(false);
    const [modalCteNfeVisible, setModalCteNfeVisible] = useState(false);
    const [contractorModalVisible, setModalContractorVisible] = useState(false);
    const [ciotModalVisible, setCiotModalVisible] = useState(false);
    const [valePedagioModalVisible, setValePedagioModalVisible] = useState(
        false
    );

    const [loading, setLoading] = useState(false);

    const methods = useForm<MDFeProps>({
        resolver: yupResolver(validations),
        defaultValues: data
    });
    const {
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        formState
    } = methods;

    watch(['type', 'citiesCteNfe', 'cte_nfe', 'insurance', '_control_city_id']);

    // RECEBER DADOS PARA PREENCHER OS COMBOBOX E INICIALIZAR O FORMULÁRIO
    useEffect(() => {
        setLoading(true);
        Promise.all([
            api.get(
                `vehiclesCombo?order=description&type=asc&form_action=${data.form_action}`
            ),
            api.get(
                `peopleCombo?order=name&type=asc&driver=true&form_action=${data.form_action}`
            ),
            api.get(`environmentsCombo?order=corporate_name&type=asc`),
            api.get(
                `insurances?page=1&limit=total&order=created_at&type=desc&form_action=${data.form_action}`
            )
        ])
            .then(
                ([
                    vehicleResponse,
                    peopleResponse,
                    environmentsResponse,
                    insurancesResponse
                ]: any) => {
                    setVehicles(
                        vehicleResponse.data.filter(
                            (vehicle: VehicleProps) =>
                                vehicle.vehicle_type === 0
                        )
                    );
                    setTrailers(
                        vehicleResponse.data.filter(
                            (trailer: VehicleProps) =>
                                trailer.vehicle_type === 1
                        )
                    );
                    setDriver(peopleResponse.data);
                    setEnvironments(environmentsResponse.data);
                    setInsurances(insurancesResponse.data.data);
                }
            )
            .catch(() => toast.error('Não foi possível efetuar a consulta'))
            .finally(() => {
                setLoading(false);
            });
    }, [data, reset]);

    useEffect(() => {
        if (vehicleRefresh) {
            setLoading(true);
            try {
                api.get(`vehiclesCombo?order=description&type=asc`).then(
                    (response) => {
                        setVehicles(
                            response.data.filter(
                                (vehicle: VehicleProps) =>
                                    vehicle.vehicle_type === 0
                            )
                        );
                        setTrailers(
                            response.data.filter(
                                (trailer: VehicleProps) =>
                                    trailer.vehicle_type === 1
                            )
                        );
                    }
                );
            } catch (error) {
                toast.error('Erro ao consultar Veículos');
            }
            setLoading(false);
        }
    }, [vehicleRefresh]); // REFRESH VEHICLES

    useEffect(() => {
        if (peopleRefresh) {
            setLoading(true);
            try {
                api.get(
                    `peopleCombo?order=name&type=asc&driver=true`
                ).then((response) => setDriver(response.data));
            } catch (error) {
                toast.error('Erro ao consultar Motoristas');
            }
            setLoading(false);
        }
    }, [peopleRefresh]); // REFRESH PEOPLE (DRIVERS)

    useEffect(() => {
        if (insuranceRefresh) {
            try {
                api.get(
                    `insurances?page=1&limit=total&order=created_at&type=asc`
                ).then((response) => {
                    setInsurances(response.data.data);
                });
            } catch (error) {
                toast.error('Erro ao consultar Seguradoras');
            }
        }
    }, [insuranceRefresh]); // REFRESH INSURANCES

    useEffect(() => {
        if (Object.entries(formState.errors).toString() !== '') {
            let errorMessage = '';
            Object.entries(formState.errors)
                .map(([key, value]) => value)
                .forEach((error: any) => {
                    errorMessage = `${errorMessage} <p>${error.message}</p>`;
                });

            Swal.fire({
                icon: 'warning',
                title: 'Verifique os seguintes campos antes de salvar:',
                html: errorMessage
            });
        }
    }, [formState.errors]); // HANDLE FORM ERRORS

    const onSubmit = useCallback(
        async (dataSubmit: MDFeProps) => {
            setLoading(true);
            (Object.keys(dataSubmit) as (keyof MDFeProps)[]).forEach((key) => {
                if (dataSubmit[key] === '') dataSubmit[key] = null as never;
            });
            switch (dataSubmit.form_action) {
                case 'include':
                    try {
                        await api.post('mdfes', {
                            ...dataSubmit,
                            log: {
                                inssuer_id: dataSubmit.environment_inssuer_id,
                                date_emission: dataSubmit.date_emission,
                                amount: dataSubmit.amount
                            }
                        });
                        toast.success('Cadastro efetuado com sucesso');
                        refresh();
                        hide();
                    } catch (error: any) {
                        toast.error(
                            error.response?.data?.errors[0]?.message ||
                                'Ocorreu um erro inesperado ao realizar o cadastro, confira a sua conexão e tente novamente'
                        );
                    }
                    break;
                case 'edit':
                    try {
                        const log = getChanges(data, dataSubmit);
                        await api.put(`mdfes/${dataSubmit.id}`, {
                            ...dataSubmit,
                            log
                        });
                        toast.success('Cadastro alterado com sucesso');
                        refresh();
                        hide();
                    } catch (error: any) {
                        toast.error(
                            error.response?.data?.errors[0]?.message ||
                                'Ocorreu um erro inesperado ao realizar o cadastro, confira a sua conexão e tente novamente'
                        );
                    }
                    break;
                default:
                    toast.error('Erro ao efetuar cadastro');
                    break;
            }
            setLoading(false);
        },
        [data]
    );

    function handleCancel() {
        Swal.fire({
            title: 'Deseja realmente cancelar?',
            icon: 'question',
            showCancelButton: true,
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                reset();
                hide();
            }
        });
    }

    return (
        <>
            <Modal show dialogAs="div">
                <FormProvider {...methods}>
                    <form
                        autoComplete="off"
                        className="responsive-modal"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Modal.Dialog
                            scrollable
                            size="xl"
                            className="mdf-modal"
                            centered
                        >
                            <Modal.Header className="bg-primary">
                                <Modal.Title>
                                    {data.form_action === 'include'
                                        ? 'Cadastrar MDF-e'
                                        : data.form_action === 'edit'
                                        ? 'Alterar MDF-e'
                                        : 'Visualizar MDF-e'}
                                </Modal.Title>
                                <span>{enviromentText}</span>
                            </Modal.Header>

                            <Modal.Body className="p-0 m-0">
                                {/* ABAS PRINCIPAIS */}
                                <div className="card-header p-1">
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <a
                                                className="nav-link active"
                                                href="#general-info"
                                                data-toggle="tab"
                                            >
                                                * MDF-e
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#loading_unloading"
                                                data-toggle="tab"
                                            >
                                                * Carregamento / Descarregamento
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#cte_nfe"
                                                data-toggle="tab"
                                            >
                                                {getValues('type') === 1
                                                    ? '* CT-e'
                                                    : '* NF-e'}
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#product"
                                                data-toggle="tab"
                                            >
                                                * Produto Predominante
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#insurance"
                                                data-toggle="tab"
                                            >
                                                * Informações do seguro
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#contractor"
                                                data-toggle="tab"
                                            >
                                                Contratante
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#ciot"
                                                data-toggle="tab"
                                            >
                                                CIOT
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a
                                                className="nav-link"
                                                href="#vale_ped"
                                                data-toggle="tab"
                                            >
                                                Vale Pedágio
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                {/* CONTEÚDO DO MODAL */}
                                <div className="tab-content p-2">
                                    {/* INFORMAÇÕES GERAIS */}
                                    <div
                                        className="tab-pane active"
                                        id="general-info"
                                    >
                                        <div className="row">
                                            <InputLabelOnlyNumbers
                                                label="Código"
                                                name="cod"
                                                data_tip={
                                                    helperTooltips.code_mdfe
                                                }
                                                size_label="1"
                                                size_input="1"
                                                disabled
                                            />

                                            <InputLabelOnlyNumbers
                                                label="N° MDF-e"
                                                name="number"
                                                data_tip={
                                                    helperTooltips.number_mdfe
                                                }
                                                size_label="1"
                                                size_input="1"
                                                disabled
                                            />

                                            <InputLabelOnlyNumbers
                                                label="Série"
                                                name="serie"
                                                data_tip={
                                                    helperTooltips.serie_mdfe
                                                }
                                                size_label="1"
                                                size_input="1"
                                                disabled
                                            />

                                            <InputLabel
                                                required
                                                label="Emissão"
                                                name="date_emission"
                                                data_tip={
                                                    helperTooltips.emission
                                                }
                                                size_label="1"
                                                size_input="2"
                                                type="date"
                                            />

                                            <InputLabel
                                                required
                                                label="Início viagem"
                                                name="date_travel"
                                                data_tip={
                                                    helperTooltips.date_travel
                                                }
                                                size_label="1 pl-0"
                                                size_input="2"
                                                type="date"
                                            />
                                        </div>
                                        <div className="row">
                                            <SelectInputLabel
                                                required
                                                label="Tipo"
                                                name="type"
                                                data_tip={
                                                    helperTooltips.type_mdfe
                                                }
                                                size_label="1"
                                                size_input="2"
                                                options={[
                                                    {
                                                        value: 1,
                                                        label: 'Transportadora'
                                                    },
                                                    {
                                                        value: 2,
                                                        label: 'Carga própria'
                                                    }
                                                ]}
                                                onChange={() =>
                                                    reset({
                                                        ...getValues(),
                                                        cte_nfe: []
                                                    })
                                                }
                                            />

                                            <SelectInputLabel
                                                required
                                                label="Transportador"
                                                name="transporter"
                                                data_tip={
                                                    helperTooltips.transporter_mdfe
                                                }
                                                size_label="1 pl-0"
                                                size_input="2 pl-4"
                                                options={[
                                                    {
                                                        value: 1,
                                                        label: 'ETC'
                                                    },
                                                    {
                                                        value: 2,
                                                        label: 'TAC'
                                                    },
                                                    {
                                                        value: 3,
                                                        label: 'CTC'
                                                    },
                                                    {
                                                        value: null,
                                                        label: 'Próprio'
                                                    }
                                                ]}
                                            />

                                            <SelectInputLabel
                                                label="Emitente"
                                                name="environment_inssuer_id"
                                                data_tip={
                                                    helperTooltips.environment_inssuer
                                                }
                                                size_label="1"
                                                size_input="5"
                                                options={environments.map(
                                                    (
                                                        item: EnvironmentProps
                                                    ) => ({
                                                        value: item.id,
                                                        label:
                                                            item.corporate_name
                                                    })
                                                )}
                                                isDisabled={true}
                                            />
                                        </div>

                                        {/* RODOVIÁRIO */}
                                        <div className="row mt-1">
                                            <div className="col-sm-12">
                                                <div className="card card-primary card-outline card-outline-tabs mb-0">
                                                    <div className="card-header p-0 border-bottom-0">
                                                        <ul
                                                            className="nav nav-tabs"
                                                            id="custom-tabs-for-tab"
                                                            role="tablist"
                                                        >
                                                            <li className="nav-item">
                                                                <a
                                                                    className="nav-link active"
                                                                    id="route-tab"
                                                                    data-toggle="pill"
                                                                    href="#route"
                                                                    role="tab"
                                                                    aria-controls="route"
                                                                    aria-selected="true"
                                                                >
                                                                    * Percurso
                                                                </a>
                                                            </li>

                                                            <li className="nav-item">
                                                                <a
                                                                    className="nav-link"
                                                                    id="vehicle-tab"
                                                                    data-toggle="pill"
                                                                    href="#vehicle"
                                                                    role="tab"
                                                                    aria-controls="vehicle"
                                                                    aria-selected="false"
                                                                >
                                                                    * Veículo
                                                                </a>
                                                            </li>

                                                            <li className="nav-item">
                                                                <a
                                                                    className="nav-link"
                                                                    id="driver-tab"
                                                                    data-toggle="pill"
                                                                    href="#driver"
                                                                    role="tab"
                                                                    aria-controls="driver"
                                                                    aria-selected="false"
                                                                >
                                                                    * Motoristas
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="card-body pl-0 pr-1 pt-3 pb-3">
                                                        <div className="tab-content">
                                                            {/* PERCURSO */}
                                                            <div
                                                                className="tab-pane fade show active"
                                                                id="route"
                                                                role="tabpanel"
                                                                aria-labelledby="route-tab"
                                                            >
                                                                <div className="row">
                                                                    <InputLabelCEPnoSearch
                                                                        required
                                                                        label="CEP carregamento"
                                                                        name="cep_load"
                                                                        data_tip={
                                                                            helperTooltips.cep_load_mdfe
                                                                        }
                                                                        onChange={(
                                                                            text
                                                                        ) => {
                                                                            if (
                                                                                ((text as unknown) as string)
                                                                                    .length ===
                                                                                9
                                                                            ) {
                                                                                searchCEP_MDFe(
                                                                                    (text as unknown) as string,
                                                                                    setValue,
                                                                                    getValues,
                                                                                    setLoading,
                                                                                    cities,
                                                                                    'city_origin_id',
                                                                                    'load'
                                                                                );
                                                                            }
                                                                        }}
                                                                        size_label="2"
                                                                        size_input="2"
                                                                    />

                                                                    <AsyncSelectLabelCities
                                                                        required
                                                                        label="Origem"
                                                                        name="city_origin_id"
                                                                        data_tip={
                                                                            helperTooltips.city_origin_mdfe
                                                                        }
                                                                        size_label="1"
                                                                        size_input="7"
                                                                        data={
                                                                            cities
                                                                        }
                                                                        onChange={(
                                                                            option: any
                                                                        ) => {
                                                                            if (
                                                                                option &&
                                                                                !getValues(
                                                                                    'load'
                                                                                ).includes(
                                                                                    option.value
                                                                                )
                                                                            ) {
                                                                                setValue(
                                                                                    'load',
                                                                                    [
                                                                                        ...getValues()
                                                                                            .load,
                                                                                        option.value
                                                                                    ]
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>

                                                                <div className="row">
                                                                    <InputLabelCEPnoSearch
                                                                        required
                                                                        label="CEP descarregamento"
                                                                        name="cep_unload"
                                                                        data_tip={
                                                                            helperTooltips.cep_unload_mdfe
                                                                        }
                                                                        onChange={(
                                                                            text
                                                                        ) => {
                                                                            if (
                                                                                ((text as unknown) as string)
                                                                                    .length ===
                                                                                9
                                                                            ) {
                                                                                searchCEP_MDFe(
                                                                                    (text as unknown) as string,
                                                                                    setValue,
                                                                                    getValues,
                                                                                    setLoading,
                                                                                    cities,
                                                                                    'city_destination_id',
                                                                                    'unload'
                                                                                );
                                                                            }
                                                                        }}
                                                                        size_label="2"
                                                                        size_input="2"
                                                                    />

                                                                    <AsyncSelectLabelCities
                                                                        required
                                                                        label="Destino"
                                                                        name="city_destination_id"
                                                                        data_tip={
                                                                            helperTooltips.city_destination_mdfe
                                                                        }
                                                                        size_label="1"
                                                                        size_input="7"
                                                                        data={
                                                                            cities
                                                                        }
                                                                        onChange={(
                                                                            option: any
                                                                        ) => {
                                                                            if (
                                                                                option &&
                                                                                !getValues(
                                                                                    'unload'
                                                                                ).includes(
                                                                                    option.value
                                                                                )
                                                                            ) {
                                                                                setValue(
                                                                                    'unload',
                                                                                    [
                                                                                        ...getValues()
                                                                                            .unload,
                                                                                        option.value
                                                                                    ]
                                                                                );
                                                                                setValue(
                                                                                    'citiesCteNfe',
                                                                                    [
                                                                                        ...getValues()
                                                                                            .citiesCteNfe,
                                                                                        option
                                                                                    ]
                                                                                );
                                                                                if (
                                                                                    getValues(
                                                                                        'citiesCteNfe'
                                                                                    )
                                                                                        .length ===
                                                                                    1
                                                                                ) {
                                                                                    setValue(
                                                                                        '_control_city_id',
                                                                                        option.value
                                                                                    );
                                                                                }
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <SelectInputLabel
                                                                        menuHeight={
                                                                            150
                                                                        }
                                                                        label="Percurso"
                                                                        name="route"
                                                                        data_tip={
                                                                            helperTooltips.route_mdfe
                                                                        }
                                                                        size_label="5"
                                                                        size_input="7"
                                                                        options={
                                                                            ufs
                                                                        }
                                                                        isMulti
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* VEÍCULO */}
                                                            <div
                                                                className="tab-pane fade"
                                                                id="vehicle"
                                                                role="tabpanel"
                                                                aria-labelledby="vehicle-tab"
                                                            >
                                                                <div className="row mb-0">
                                                                    <InputLabelOnlyNumbers
                                                                        label="RNTRC"
                                                                        required={
                                                                            getValues(
                                                                                'type'
                                                                            ) ===
                                                                                1 ||
                                                                            rntrcIsRequired
                                                                        }
                                                                        name="rntrc"
                                                                        data_tip={
                                                                            helperTooltips.rntrc
                                                                        }
                                                                        size_label="1"
                                                                        size_input="2"
                                                                        digits={
                                                                            8
                                                                        }
                                                                    />

                                                                    <SelectInputLabel
                                                                        required
                                                                        label="Veículo"
                                                                        name="vehicle_id"
                                                                        data_tip={
                                                                            helperTooltips.description_vehicle
                                                                        }
                                                                        size_label="1"
                                                                        size_input="7"
                                                                        options={vehicles.map(
                                                                            (
                                                                                item: VehicleProps
                                                                            ) => ({
                                                                                value:
                                                                                    item.id,
                                                                                label: `${
                                                                                    item.license_plate
                                                                                } - ${
                                                                                    item.description
                                                                                }${
                                                                                    item
                                                                                        .driver
                                                                                        ?.name
                                                                                        ? ` - ${item.driver.name}`
                                                                                        : ''
                                                                                }`
                                                                            })
                                                                        )}
                                                                        onChange={(
                                                                            op: any
                                                                        ) => {
                                                                            const vehicle = vehicles.find(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    item.id ===
                                                                                    op.value
                                                                            );
                                                                            setRntrcIsRequired(
                                                                                vehicle?.owner ===
                                                                                    1
                                                                            );
                                                                            setValue(
                                                                                'rntrc',
                                                                                vehicle?.rntrc ||
                                                                                    ''
                                                                            );
                                                                            if (
                                                                                vehicle?.people_driver_id &&
                                                                                !getValues(
                                                                                    'driver'
                                                                                ).includes(
                                                                                    vehicle?.people_driver_id
                                                                                )
                                                                            ) {
                                                                                setValue(
                                                                                    'driver',
                                                                                    [
                                                                                        ...getValues(
                                                                                            'driver'
                                                                                        ),
                                                                                        vehicle?.people_driver_id
                                                                                    ]
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                    <EditIncludeButtons
                                                                        setLoading={
                                                                            setLoading
                                                                        }
                                                                        setModalData={
                                                                            setVehicleModalData
                                                                        }
                                                                        setModalVisible={
                                                                            setVehicleModalVisible
                                                                        }
                                                                        defaultValues={{
                                                                            ...vehicleDefaultValues,
                                                                            vehicle_type: 0
                                                                        }}
                                                                        route="vehicles"
                                                                        field="vehicle_id"
                                                                        tip="Veículo"
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <SelectInputLabel
                                                                        label="Reboque"
                                                                        name="trailer"
                                                                        data_tip={
                                                                            helperTooltips.trailer
                                                                        }
                                                                        size_label="4"
                                                                        size_input="7"
                                                                        isMulti
                                                                        options={trailers.map(
                                                                            (
                                                                                item: VehicleProps
                                                                            ) => ({
                                                                                value:
                                                                                    item.id,
                                                                                label: `${
                                                                                    item.license_plate
                                                                                } - ${
                                                                                    item.description
                                                                                }${
                                                                                    item
                                                                                        .driver
                                                                                        ?.name
                                                                                        ? ` - ${item.driver.name}`
                                                                                        : ''
                                                                                }`
                                                                            })
                                                                        )}
                                                                    />
                                                                    <EditIncludeButtons
                                                                        setLoading={
                                                                            setLoading
                                                                        }
                                                                        setModalData={
                                                                            setVehicleModalData
                                                                        }
                                                                        setModalVisible={
                                                                            setVehicleModalVisible
                                                                        }
                                                                        defaultValues={{
                                                                            ...vehicleDefaultValues,
                                                                            vehicle_type: 1
                                                                        }}
                                                                        route="vehicles"
                                                                        field="trailer"
                                                                        tip="Reboque"
                                                                        isMulti
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* MOTORISTAS */}
                                                            <div
                                                                className="tab-pane fade"
                                                                id="driver"
                                                                role="tabpanel"
                                                                aria-labelledby="driver-tab"
                                                            >
                                                                <div className="row">
                                                                    <SelectInputLabel
                                                                        label="Motoristas"
                                                                        required
                                                                        name="driver"
                                                                        data_tip={
                                                                            helperTooltips.driver_vehicle
                                                                        }
                                                                        size_label="1"
                                                                        size_input="10"
                                                                        options={driver.map(
                                                                            (
                                                                                item
                                                                            ) => ({
                                                                                value:
                                                                                    item.id,
                                                                                label: item.name_combo as string
                                                                            })
                                                                        )}
                                                                        isMulti
                                                                    />
                                                                    <EditIncludeButtons
                                                                        setLoading={
                                                                            setLoading
                                                                        }
                                                                        setModalData={
                                                                            setDriverModalData
                                                                        }
                                                                        setModalVisible={
                                                                            setDriverModalVisible
                                                                        }
                                                                        defaultValues={{
                                                                            ...peopleDefaultValues,
                                                                            driver: true,
                                                                            type: 0
                                                                        }}
                                                                        route="people"
                                                                        field="driver"
                                                                        isMulti
                                                                        tip="Motorista"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* TOTAIS E OBS */}
                                        <div className="row mt-2">
                                            <div className="col-sm-12">
                                                <div className="card card-primary card-outline card-outline-tabs mb-0">
                                                    <div className="card-header p-0 border-bottom-0">
                                                        <ul
                                                            className="nav nav-tabs"
                                                            id="custom-tabs-for-tab"
                                                            role="tablist"
                                                        >
                                                            <li className="nav-item">
                                                                <a
                                                                    className="nav-link active"
                                                                    id="totals-tab"
                                                                    data-toggle="pill"
                                                                    href="#totals"
                                                                    role="tab"
                                                                    aria-controls="totals"
                                                                    aria-selected="true"
                                                                >
                                                                    * Totais
                                                                </a>
                                                            </li>

                                                            <li className="nav-item">
                                                                <a
                                                                    className="nav-link"
                                                                    id="obs-tab"
                                                                    data-toggle="pill"
                                                                    href="#obs"
                                                                    role="tab"
                                                                    aria-controls="obs"
                                                                    aria-selected="false"
                                                                >
                                                                    Observações
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className="card-body p-2 pt-3 pb-3">
                                                        <div className="tab-content">
                                                            {/* TOTAIS */}
                                                            <div
                                                                className="tab-pane fade show active"
                                                                id="totals"
                                                                role="tabpanel"
                                                                aria-labelledby="totals-tab"
                                                            >
                                                                <div className="row">
                                                                    <InputLabelCurrency
                                                                        required
                                                                        label="Valor total da carga"
                                                                        data_tip="Soma dos valores de todas as cargas/notas fiscais incluídas neste Manifesto"
                                                                        name="amount"
                                                                        size_label="2"
                                                                        size_input="2"
                                                                    />
                                                                </div>
                                                                <div className="row">
                                                                    <InputLabelMeasurement
                                                                        required
                                                                        label="Peso total da carga"
                                                                        data_tip="Peso total da carga"
                                                                        name="total_weight"
                                                                        size_label="2"
                                                                        size_input="2"
                                                                        precision={
                                                                            2
                                                                        }
                                                                    />

                                                                    <SelectInputLabel
                                                                        menuPlace="top"
                                                                        required
                                                                        label="Unidade de medida"
                                                                        data_tip="Unidade de medida"
                                                                        name="measurement"
                                                                        size_label="2"
                                                                        size_input="2"
                                                                        options={[
                                                                            {
                                                                                value:
                                                                                    '01',
                                                                                label:
                                                                                    'KG'
                                                                            },
                                                                            {
                                                                                value:
                                                                                    '02',
                                                                                label:
                                                                                    'TON'
                                                                            }
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* OBS */}
                                                            <div
                                                                className="tab-pane fade"
                                                                id="obs"
                                                                role="tabpanel"
                                                                aria-labelledby="obs-tab"
                                                            >
                                                                <div className="row">
                                                                    <InputLabelTextArea
                                                                        label=""
                                                                        name="obs"
                                                                        size_label=""
                                                                        size_input="12"
                                                                        remove_label
                                                                        rows={5}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* CARREGAMENTO E DESCARREGAMENTO */}
                                    <div
                                        className="tab-pane"
                                        id="loading_unloading"
                                    >
                                        <div className="row mb-2">
                                            <AsyncSelectLabelCities
                                                required
                                                label="Carregamento"
                                                name="load"
                                                data_tip="Cidade(s) de carregamento"
                                                size_label="2"
                                                size_input="10"
                                                data={cities}
                                                isMulti
                                            />
                                        </div>
                                        <div className="row">
                                            <AsyncSelectLabelCities
                                                required
                                                label="Descarregamento"
                                                name="unload"
                                                size_label="2"
                                                size_input="10"
                                                data_tip="Cidade(s) de descarregamento"
                                                data={cities}
                                                isMulti
                                                onChange={(option: any) => {
                                                    const city_ids = option.map(
                                                        (op: any) => op.value
                                                    );
                                                    const removedSelected =
                                                        option?.length <
                                                            getValues(
                                                                'citiesCteNfe'
                                                            ).length &&
                                                        getValues(
                                                            'citiesCteNfe'
                                                        ).find(
                                                            (city) =>
                                                                !getValues(
                                                                    'unload'
                                                                ).includes(
                                                                    city.value
                                                                )
                                                        )?.value ===
                                                            getValues(
                                                                '_control_city_id'
                                                            );
                                                    reset({
                                                        ...getValues(),
                                                        citiesCteNfe: option,
                                                        cte_nfe: getValues(
                                                            'cte_nfe'
                                                        ).filter(
                                                            (cte_nfe_item) =>
                                                                city_ids.includes(
                                                                    cte_nfe_item.cte_city_id
                                                                )
                                                        )
                                                    });
                                                    if (
                                                        getValues(
                                                            'citiesCteNfe'
                                                        ).length === 0
                                                    ) {
                                                        setValue(
                                                            '_control_city_id',
                                                            0
                                                        );
                                                    }
                                                    if (
                                                        getValues(
                                                            'citiesCteNfe'
                                                        ).length === 1
                                                    ) {
                                                        setValue(
                                                            '_control_city_id',
                                                            option[0].value
                                                        );
                                                    }
                                                    if (removedSelected) {
                                                        if (
                                                            getValues(
                                                                'citiesCteNfe'
                                                            ).length > 0
                                                        ) {
                                                            setValue(
                                                                '_control_city_id',
                                                                getValues(
                                                                    'citiesCteNfe'
                                                                )[0].value
                                                            );
                                                        } else {
                                                            setValue(
                                                                '_control_city_id',
                                                                0
                                                            );
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* CTE / NFE */}
                                    <div className="tab-pane" id="cte_nfe">
                                        <div className="row mb-1">
                                            <SelectInputLabel
                                                label="Cidade de descarregamento"
                                                name="_control_city_id"
                                                data_tip="Cidade onde a carga referente ao documento será descarregado."
                                                size_label="2"
                                                size_input="4"
                                                options={
                                                    getValues('citiesCteNfe')
                                                        .length === 0
                                                        ? [
                                                              {
                                                                  value: 0,
                                                                  label: ''
                                                              }
                                                          ]
                                                        : getValues()
                                                              .citiesCteNfe
                                                }
                                                isDisabled={
                                                    getValues('citiesCteNfe')
                                                        .length === 0
                                                }
                                            />

                                            <div className="col-sm-6 text-right">
                                                <button
                                                    type="button"
                                                    className="btn btn-default btn-sm"
                                                    disabled={
                                                        getValues(
                                                            'form_action'
                                                        ) === 'view'
                                                    }
                                                    onClick={() =>
                                                        setModalCteNfeVisible(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <i className="fa fa-plus mr-1 c-success" />
                                                    Incluir
                                                </button>
                                            </div>
                                        </div>

                                        <Table
                                            id="cte_nfe"
                                            columns={[
                                                {
                                                    text: 'Chave de acesso',
                                                    width: '100%',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'Série',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'Número',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'Remover',
                                                    className: 'text-center'
                                                }
                                            ]}
                                        >
                                            {getValues('cte_nfe') &&
                                                getValues()
                                                    .cte_nfe.filter(
                                                        (tableItem) =>
                                                            tableItem.cte_city_id ===
                                                            getValues()
                                                                ._control_city_id
                                                    )
                                                    .map((item) => (
                                                        <tr
                                                            key={`${item.cte_city_id}-${item.access_key}`}
                                                        >
                                                            <td className="pt-2">
                                                                {
                                                                    item.access_key
                                                                }
                                                            </td>
                                                            <td className="pt-2">
                                                                {getDfeKeySerie(
                                                                    item.access_key
                                                                )}
                                                            </td>
                                                            <td className="pt-2">
                                                                {getDfeKeyNumber(
                                                                    item.access_key
                                                                )}
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-xs"
                                                                    disabled={
                                                                        getValues(
                                                                            'form_action'
                                                                        ) ===
                                                                        'view'
                                                                    }
                                                                    onClick={() => {
                                                                        reset({
                                                                            ...getValues(),
                                                                            cte_nfe: getValues(
                                                                                'cte_nfe'
                                                                            ).filter(
                                                                                (
                                                                                    cteNfeItem
                                                                                ) =>
                                                                                    cteNfeItem.access_key !==
                                                                                    item.access_key
                                                                            )
                                                                        });
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                        </Table>
                                    </div>

                                    {/* PRODUTO */}
                                    <div className="tab-pane" id="product">
                                        <div className="row">
                                            <SelectInputLabel
                                                label="Tipo de carga"
                                                data_tip="Tipo da carga predominante."
                                                required
                                                name="load_type"
                                                size_label="2"
                                                size_input="6"
                                                options={[
                                                    {
                                                        value: '01',
                                                        label: 'GRANEL SÓLIDO'
                                                    },
                                                    {
                                                        value: '02',
                                                        label: 'GRANEL LÍQUIDO'
                                                    },
                                                    {
                                                        value: '03',
                                                        label: 'FRIGORIFICADA'
                                                    },
                                                    {
                                                        value: '04',
                                                        label: 'CONTEINERIZADA'
                                                    },
                                                    {
                                                        value: '05',
                                                        label: 'CARGA GERAL'
                                                    },
                                                    {
                                                        value: '06',
                                                        label: 'NEOGRANEL'
                                                    },
                                                    {
                                                        value: '07',
                                                        label:
                                                            'PERIGOSA (GRANEL SÓLIDO)'
                                                    },
                                                    {
                                                        value: '08',
                                                        label:
                                                            'PERIGOSA (GRANEL LÍQUIDO)'
                                                    },
                                                    {
                                                        value: '09',
                                                        label:
                                                            'PERIGOSA (CARGA FRIGORIFICADA)'
                                                    },
                                                    {
                                                        value: '10',
                                                        label:
                                                            'PERIGOSA (CONTEINERIZADA)'
                                                    },
                                                    {
                                                        value: '11',
                                                        label:
                                                            'PERIGOSA (CARGA GERAL)'
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div className="row">
                                            <InputLabel
                                                required
                                                label="Produto chave"
                                                data_tip="Produto com a carga de maior valor"
                                                name="product_key"
                                                size_label="2"
                                                size_input="6"
                                            />
                                        </div>
                                        <div className="row">
                                            <InputLabelOnlyNumbers
                                                label="EAN"
                                                data_tip="Código de barras com 13 dígitos."
                                                name="ean"
                                                size_label="2"
                                                size_input="2"
                                                digits={13}
                                            />

                                            <InputLabelOnlyNumbers
                                                label="NCM"
                                                data_tip="Nomenclatura Comum do Mercosul."
                                                name="ncm"
                                                size_label="2"
                                                size_input="2"
                                                digits={8}
                                            />
                                        </div>
                                    </div>

                                    {/* SEGURO */}
                                    <div className="tab-pane" id="insurance">
                                        <div className="row mb-1">
                                            <div className="col-sm-12 text-right">
                                                <button
                                                    type="button"
                                                    className="btn btn-default btn-sm"
                                                    disabled={
                                                        getValues(
                                                            'form_action'
                                                        ) === 'view'
                                                    }
                                                    onClick={() =>
                                                        setInsuranceModalVisible(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <i className="fa fa-plus mr-1 c-success" />
                                                    Incluir
                                                </button>
                                            </div>
                                        </div>
                                        <Table
                                            id="mdfe"
                                            columns={[
                                                {
                                                    text: 'Responsável',
                                                    width: '15%',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'Seguradora',
                                                    width: '55% ',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'Apólice',
                                                    width: '15% ',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'Averbação',
                                                    width: '15% ',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'Remover',
                                                    className: 'text-center'
                                                }
                                            ]}
                                        >
                                            {getValues('insurance').map(
                                                (item, index) => (
                                                    <tr
                                                        key={`${item.insurance_id}-${item.policy}-${item.endorsement}`}
                                                    >
                                                        <td className="pt-2">
                                                            {item.insurance_responsible ===
                                                            1
                                                                ? 'Emitente do CT-e'
                                                                : 'Contratante'}
                                                        </td>
                                                        <td className="pt-2">
                                                            {
                                                                insurances.find(
                                                                    (
                                                                        insurance
                                                                    ) =>
                                                                        insurance.id ===
                                                                        item.insurance_id
                                                                )
                                                                    ?.corporate_name
                                                            }
                                                        </td>
                                                        <td className="pt-2">
                                                            {item.policy}
                                                        </td>
                                                        <td className="pt-2">
                                                            {item.endorsement}
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-xs"
                                                                disabled={
                                                                    getValues(
                                                                        'form_action'
                                                                    ) === 'view'
                                                                }
                                                                onClick={() => {
                                                                    reset({
                                                                        ...getValues(),
                                                                        insurance: getValues(
                                                                            'insurance'
                                                                        ).filter(
                                                                            (
                                                                                insuranceItem,
                                                                                insuranceIndex
                                                                            ) =>
                                                                                index !==
                                                                                insuranceIndex
                                                                        )
                                                                    });
                                                                }}
                                                            >
                                                                <i className="fas fa-trash" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </Table>
                                    </div>

                                    {/* CONTRATANTE */}
                                    <div className="tab-pane" id="contractor">
                                        <div className="row mb-1">
                                            <div className="col-sm-12 text-right">
                                                <button
                                                    type="button"
                                                    className="btn btn-default btn-sm"
                                                    disabled={
                                                        getValues(
                                                            'form_action'
                                                        ) === 'view'
                                                    }
                                                    onClick={() =>
                                                        setModalContractorVisible(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <i className="fa fa-plus mr-1 c-success" />
                                                    Incluir
                                                </button>
                                            </div>
                                        </div>
                                        <Table
                                            id="mdfe"
                                            columns={[
                                                {
                                                    text:
                                                        'CPF/CNPJ (contratante)',
                                                    className: 'text-max-sm',
                                                    width: '100%'
                                                },
                                                {
                                                    text: 'Remover',
                                                    className: 'text-center'
                                                }
                                            ]}
                                        >
                                            {getValues('contractor').map(
                                                (item) => (
                                                    <tr key={item}>
                                                        <td className="pt-2">
                                                            {item}
                                                        </td>
                                                        <td className="text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-xs"
                                                                disabled={
                                                                    getValues(
                                                                        'form_action'
                                                                    ) === 'view'
                                                                }
                                                                onClick={() => {
                                                                    reset({
                                                                        ...getValues(),
                                                                        contractor: getValues(
                                                                            'contractor'
                                                                        ).filter(
                                                                            (
                                                                                contractorItem
                                                                            ) =>
                                                                                contractorItem !==
                                                                                item
                                                                        )
                                                                    });
                                                                }}
                                                            >
                                                                <i className="fas fa-trash" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </Table>
                                    </div>

                                    {/* CIOT */}
                                    <div className="tab-pane" id="ciot">
                                        <div className="row mb-1">
                                            <div className="col-sm-12 text-right">
                                                <button
                                                    type="button"
                                                    className="btn btn-default btn-sm"
                                                    disabled={
                                                        getValues(
                                                            'form_action'
                                                        ) === 'view'
                                                    }
                                                    onClick={() =>
                                                        setCiotModalVisible(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <i className="fa fa-plus mr-1 c-success" />
                                                    Incluir
                                                </button>
                                            </div>
                                        </div>

                                        <Table
                                            id="mdfe"
                                            columns={[
                                                {
                                                    text:
                                                        'CPF/CNPJ (contratante do serviço)',
                                                    width: '30%',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'CIOT',
                                                    width: '70% ',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'Remover',
                                                    className: 'text-center'
                                                }
                                            ]}
                                        >
                                            {getValues('ciot') &&
                                                getValues('ciot').map(
                                                    (item, index) => (
                                                        <tr
                                                            key={`${item.ciot}-${item.cpf_cnpj_service}`}
                                                        >
                                                            <td className="pt-2">
                                                                {
                                                                    item.cpf_cnpj_service
                                                                }
                                                            </td>
                                                            <td className="pt-2">
                                                                {item.ciot}
                                                            </td>
                                                            <td className="text-center">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-xs"
                                                                    disabled={
                                                                        getValues(
                                                                            'form_action'
                                                                        ) ===
                                                                        'view'
                                                                    }
                                                                    onClick={() => {
                                                                        reset({
                                                                            ...getValues(),
                                                                            ciot: getValues(
                                                                                'ciot'
                                                                            ).filter(
                                                                                (
                                                                                    ciotItem,
                                                                                    ciotIndex
                                                                                ) =>
                                                                                    ciotIndex !==
                                                                                    index
                                                                            )
                                                                        });
                                                                    }}
                                                                >
                                                                    <i className="fas fa-trash" />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                        </Table>
                                    </div>

                                    {/* VALE PEDAGIO */}
                                    <div className="tab-pane" id="vale_ped">
                                        <div className="mb-2 d-flex align-items-center">
                                            <SelectInputLabel
                                                required
                                                label="Tipo"
                                                name="veic_category_comb"
                                                size_label="1 pl-0"
                                                size_input="6"
                                                options={[
                                                    {
                                                        value: '02',
                                                        label:
                                                            'Veículo Comercial 2 eixos'
                                                    },
                                                    {
                                                        value: '04',
                                                        label:
                                                            'Veículo Comercial 3 eixos'
                                                    },
                                                    {
                                                        value: '06',
                                                        label:
                                                            'Veículo Comercial 4 eixos'
                                                    },
                                                    {
                                                        value: '07',
                                                        label:
                                                            'Veículo Comercial 5 eixos'
                                                    },
                                                    {
                                                        value: '08',
                                                        label:
                                                            'Veículo Comercial 6 eixos'
                                                    },
                                                    {
                                                        value: '10',
                                                        label:
                                                            'Veículo Comercial 7 eixos'
                                                    },
                                                    {
                                                        value: '11',
                                                        label:
                                                            'Veículo Comercial 8 eixos'
                                                    },
                                                    {
                                                        value: '12',
                                                        label:
                                                            'Veículo Comercial 9 eixos'
                                                    },

                                                    {
                                                        value: '13',
                                                        label:
                                                            'Veículo Comercial 10 eixos'
                                                    },
                                                    {
                                                        value: '14',
                                                        label:
                                                            'Veículo Comercial Acima de 10 eixos'
                                                    }
                                                ]}
                                            />

                                            <button
                                                type="button"
                                                className="btn btn-default btn-sm"
                                                disabled={
                                                    getValues('form_action') ===
                                                    'view'
                                                }
                                                onClick={() =>
                                                    setValePedagioModalVisible(
                                                        true
                                                    )
                                                }
                                            >
                                                <i className="fa fa-plus mr-1 c-success" />
                                                Incluir
                                            </button>
                                        </div>

                                        <Table
                                            id="valePedagio"
                                            columns={[
                                                {
                                                    text: 'Cnpj Fornecedor',
                                                    width: '20%',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'Cnpj Pagador',
                                                    width: '20% ',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'Comprovante',
                                                    width: '25% ',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'Cpf Pagador',
                                                    width: '20% ',
                                                    className: 'text-max-sm'
                                                },
                                                {
                                                    text: 'valor',
                                                    width: '15% ',
                                                    className: 'text-center'
                                                },
                                                {
                                                    text: 'Remover',
                                                    className: 'text-center'
                                                }
                                            ]}
                                        >
                                            {getValues('vale_pedagio') &&
                                                getValues('vale_pedagio').map(
                                                    (item, index) => {
                                                        return (
                                                            <tr
                                                                key={`${item.id}`}
                                                            >
                                                                <td className="pt-2">
                                                                    {
                                                                        item.cnpj_company
                                                                    }
                                                                </td>
                                                                <td className="pt-2">
                                                                    {
                                                                        item.cnpj_company_payer
                                                                    }
                                                                </td>
                                                                <td className="pt-2">
                                                                    {
                                                                        item.number
                                                                    }
                                                                </td>
                                                                <td className="pt-2">
                                                                    {
                                                                        item.cpf_payer
                                                                    }
                                                                </td>
                                                                <td className="pt-2">
                                                                    {`R$ ${new Intl.NumberFormat(
                                                                        'pt-BR',
                                                                        {
                                                                            minimumFractionDigits: 2,
                                                                            useGrouping: true
                                                                        }
                                                                    ).format(
                                                                        item.value
                                                                    )}`}
                                                                </td>
                                                                <td className="text-center">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-xs"
                                                                        disabled={
                                                                            getValues(
                                                                                'form_action'
                                                                            ) ===
                                                                            'view'
                                                                        }
                                                                        onClick={() => {
                                                                            reset(
                                                                                {
                                                                                    ...getValues(),
                                                                                    vale_pedagio: getValues(
                                                                                        'vale_pedagio'
                                                                                    ).filter(
                                                                                        (
                                                                                            cvale_pedagioItem,
                                                                                            vale_pedagioIndex
                                                                                        ) =>
                                                                                            vale_pedagioIndex !==
                                                                                            index
                                                                                    )
                                                                                }
                                                                            );
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-trash" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                        </Table>
                                    </div>
                                </div>
                            </Modal.Body>

                            <Modal.Footer
                                style={{
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center'
                                }}
                            >
                                {data.form_action !== 'view' && (
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Cancelar"
                                            onClick={handleCancel}
                                        />

                                        <Button
                                            type="submit"
                                            color="primary"
                                            size="btn btn-success"
                                            icon="fa fa-save"
                                            caption="Salvar"
                                            isLoading={loading}
                                            disabled={loading}
                                        />
                                    </div>
                                )}
                                {data.form_action === 'view' && (
                                    <div className="float-left responsive-button">
                                        <Button
                                            type="button"
                                            color="danger"
                                            size="btn btn-default"
                                            icon="fa fa-arrow-circle-left"
                                            caption="Cancelar"
                                            onClick={hide}
                                        />
                                    </div>
                                )}
                            </Modal.Footer>

                            <ReactTooltip
                                id="tip"
                                place="top"
                                backgroundColor="#42494E"
                                effect="solid"
                                globalEventOff="click"
                            />
                        </Modal.Dialog>
                    </form>
                </FormProvider>
            </Modal>

            {vehicleModalVisible && (
                <VehicleModal
                    data={vehicleModalData}
                    hide={() => setVehicleModalVisible(false)}
                    refresh={() => setVehicleRefresh(Math.random())}
                />
            )}

            {driverModalVisible && (
                <PeopleModal
                    data={driverModalData}
                    hide={() => setDriverModalVisible(false)}
                    refresh={setPeopleRefresh}
                />
            )}

            {modalCteNfeVisible && (
                <CteNfeModal
                    hide={() => setModalCteNfeVisible(false)}
                    setTableValue={setValue}
                    getTableValues={getValues}
                />
            )}

            {insuranceModalVisible && (
                <InsuranceModal
                    hide={() => setInsuranceModalVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                    insurances={insurances}
                    setInsuranceRefresh={() =>
                        setInsuranceRefresh(Math.random())
                    }
                />
            )}

            {contractorModalVisible && (
                <ContractorModal
                    hide={() => setModalContractorVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                />
            )}

            {ciotModalVisible && (
                <CiotModal
                    hide={() => setCiotModalVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                />
            )}

            {valePedagioModalVisible && (
                <ValePedagioModal
                    hide={() => setValePedagioModalVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                />
            )}

            {loading && <FullPageLoader />}
        </>
    );
};

export default ModalMDF;
